.section-store-locator {
  &.device-pc {
    .store-locator {
      .local-search-form {
        &__inner {
          .local-search-form__city {
            width: 100% !important;
          }
        }
      }
    }
  }
}
